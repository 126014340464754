// extracted by mini-css-extract-plugin
export var bagContainer = "ProductCard-module--bagContainer--39254";
export var detailsContainer = "ProductCard-module--detailsContainer--8f93b";
export var heartContainer = "ProductCard-module--heartContainer--f2a71";
export var heartFillContainer = "ProductCard-module--heartFillContainer--11d63";
export var hide = "ProductCard-module--hide--690c5";
export var imageContainer = "ProductCard-module--imageContainer--00924";
export var meta = "ProductCard-module--meta--07643";
export var originalPrice = "ProductCard-module--originalPrice--4251c";
export var prices = "ProductCard-module--prices--cd435";
export var productName = "ProductCard-module--productName--e6c17";
export var root = "ProductCard-module--root--d7fcc";
export var salePrice = "ProductCard-module--salePrice--3086a";
export var show = "ProductCard-module--show--6ee67";